/* Conat Events Business HTML Template */

@media only screen and (max-width: 1800px){
	
	
	
}

@media only screen and (max-width: 1400px){
	
	.about-two_image img{
		max-width: 100%;
	}

	.about-two_image-inner{
		padding-left: var(--padding-zero);
	}
	
}

@media only screen and (max-width: 1340px){

	.feature-one_pattern-three{
		display: none;
	}

	.slider-two_timer{
		right: 0px;
		bottom: 10px;
	}
	
}

@media only screen and (max-width: 1240px){
	
	.about-one_pattern-two,
	.about-one_pattern-four{
		display: none;
	}

	.about-one_image-inner{
		padding-right: var(--padding-zero);
	}

	.slider-one_play{
		right: 0px;
		top: 0px;
	}

	.about-one_image{
		margin-left: var(--margin-zero);
	}
	
}

@media only screen and (max-width: 1140px){
	
	h2{
		font-size:var(--font-40);
	}

	.speaker-block_one-image{
		width: 240px;
		height: 240px;
		margin: 0 auto;
	}

	.slider-two_icon-one,
	.slider-two_icon-two,
	.slider-two_timer{
		display: none;
	}

	.event-two{
		text-align: center;
	}

	.slider-three_content-inner{
		padding-top: 100px;
	}

	.event-two .event-block_two{
		width: 25%;
	}

	.speakers-two .inner-container{
		padding: var(--padding-zero);
	}

	.slider-three_heading{
		line-height: 70px;
		font-size: var(--font-60);
	}

	.speaker-tabs_two .tabs-content{
		padding-left: var(--padding-zero);
		padding-right: var(--padding-zero);
	}

	.footer-three_pattern,
	.price-block_three-pattern{
		display: none;
	}

	.price-block_three-inner{
		background-color: var(--white-color);
	}

	.price-block_three-price{
		font-size: var(--font-60);
	}

	.price-block_three-inner{
		padding: 50px 35px 60px;
	}

	.price-block_one-price{
		font-size: var(--font-50);
	}

	.about-one_pattern-one,
	.main-header.header-two .appointmeny_button-box{
		display: none;
	}

	.slider-three .time-countdown .counter-column .count{
		font-size: var(--font-40);
	}

	.slider-three .time-countdown .counter-column{
		font-size: var(--font-16);
	}
	
	.contact-page-section-two .info-column .inner-column{
		margin-left: var(--margin-zero);
	}

	.rtl .about-one_image{
		margin-right: var(--margin-zero);
	}

	.rtl .slider-one .owl-nav{
		left: 30px;
	}

}

@media only screen and (min-width: 768px){
	
	
	
}

@media only screen and (max-width: 870px){
	
	
	
}


@media only screen and (max-width: 976px){
	
	
	
}
	
@media only screen and (max-width: 1023px){
	
	.main-header .main-box .logo-box {
		position:relative;
		left:0px;
		top:0px;
		right:0px;
		bottom:0px;
		margin:0px;
		text-align:left;
	}
	
	.main-header .outer-box{
		display:block;
	}

	.main-header{
		position:relative;
		background: var(--color-four);;
	}
	
	.main-header .nav-outer{
		width:100%;
		text-align:center;
	}
	
	.main-header .mobile-nav-toggler{
		display:block;
	}
	
	.main-header .sticky-header .mobile-nav-toggler{
		display:block;
	}
	
	.slider-two_cloud-layer,
	.slider-two_image-column,
	.slider-two_icon-three,
	.main-header .nav-outer,
	.page-title_big,
	.contact-form-section .image-column .image,
	.main-header .sticky-header .main-menu,
	.main-header.header-style-two .sticky-header .main-menu,
	.main-header.header-style-two .nav-outer,
	.main-header.header-style-three .sticky-header .main-menu,
	.main-header.header-style-three .nav-outer{
		display:none;
	}
	
	.main-header.header-style-two .sticky-header .mobile-nav-toggler{
		margin-top:var(--margin-zero);
		margin-right:var(--margin-zero);
	}
	
	.main-header.header-style-two .mobile-nav-toggler{
		display:block;
		padding:0px;
		margin-top:-120px;
		font-size:var(--font-34);
		margin-right:var(--margin-right-15);
	}
	
	.main-header.header-style-three .mobile-nav-toggler{
		display:block;
	}
	
	.main-header #navbarSupportedContent{
		display:block;
	}
	
	.main-header .mCSB_inside > .mCSB_container{
		margin-right:0px;
	}

	.slider-one_title{
		position: relative;
		left: 0px;
		top: 0px;
		transform: rotate(0deg);
	}

	.slider-one_content-inner{
		padding-left: var(--padding-zero);
	}

	h1{
		line-height: 70px;
		font-size: var(--font-60);
	}

	.speakers-three_image,
	.video-one_pattern-three,
	.about-one_pattern-one,
	.slider-one_pattern-two{
		display: none;
	}
	.about-one{
		padding-top: var(--padding-top-110);
	}

	.about-one_content-column{
		order: 1;
	}

	.about-one_image-column{
		order: 2;
	}

	.leadership-tabs .tab-btns{
		text-align: center;
		justify-content: center;
	}

	.leadership-one_block-inner{
		padding: 30px 30px;
	}

	.slider-two_slide{
		padding: 200px 0px 120px;
	}

	.slider-two_content-inner{
		padding-top: var(--padding-zero);
	}

	.slider-two_heading::before{
		display: none;
	}

	.slider-two_button{
		margin-left: var(--margin-zero);
	}

	.leadership-two_block-inner{
		min-height: auto;
		padding: var(--padding-zero);
	}

	.leadership-two_block-image{
		position: relative;
	}

	.leadership-two_block-content{
		top: 0px;
	}

	.leadership-two_block{
		margin-bottom: var(--margin-bottom-35);
	}

	.leadership-two_block.style-two .leadership-two_block-content{
		padding-right: var(--padding-zero);
	}

	.leadership-two_block.style-two .leadership-two_block-inner{
		padding-right: var(--padding-zero);
	}

	.subscribe-two_heading{
		margin-bottom: var(--margin-bottom-25);
	}

	.footer-bottom{
		text-align: center;
	}

	.footer-bottom-nav{
		margin-top: var(--margin-top-5);
		text-align: center;
	}

	.main-header.header-two .mobile-nav-toggler{
		color: var(--color-two);
		margin-left: var(--margin-zero);
	}

	.slider-three_image img{
		right: -15px;
		max-width: 100% !important;
	}

	.business-one_image-inner{
		padding-left: var(--padding-zero);
	}

	.speaker-tabs_two .tab-btns{
		text-align: left;
	}

	.speaker-tabs_two{
		margin-top: var(--margin-zero);
	}

	.leadership-three_block-inner{
		padding: 30px 30px;
		background-color: var(--white-color);
	}

	.leadership-three_block{
		box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.10);
	}

	.leadership-three_block-inner:hover .leadership-three_block-content {
		box-shadow: inherit !important;
	  }

	.leadership-three_block-content{
		min-height: auto;
		border-radius: 0px;
		background: none;
		padding: var(--padding-zero);
	}

	.leadership-three_block-image{
		position: relative;
		margin-bottom: var(--margin-bottom-30);
	}

	.leadership-three_block-timing{
		position: relative;
		right: 0px;
		padding: 12px 40px;
		border-radius: 50px;
		display: inline-block;
		margin-top: var(--margin-top-20);
	}

	.page-title{
		padding: 220px 0px 120px;
	}

	.faq-two_form-box{
		margin-top: var(--margin-top-35);
	}

	.faq-two_form-box {
		padding: 30px 30px 40px;
	}

	.speaker-detail .counter-column p{
		margin-top: var(--margin-top-20);
	}

	.speaker-detail_author-column{
		margin-bottom: var(--margin-bottom-30);
	}

	.speaker-schedule_form{
		margin-top: var(--margin-top-30);
	}

	.event-detail_author{
		margin-top: var(--margin-top-40);
	}

	.sidebar-page-container.left-sidebar .content-side {
		order: 1;
	}

	.sidebar-page-container.left-sidebar .sidebar-side {
		order: 2;
	}

	.reply-comment{
		margin-left: var(--margin-zero);
	}

	.subscribe-one_heading{
		margin-bottom: var(--margin-bottom-25);
	}

	.rtl .main-header .mobile-nav-toggler{
		margin-left: var(--margin-zero);
		margin-right: var(--margin-right-10);
	}

	.rtl .slider-one_title{
		right: 0px;
	}

	.rtl .slider-one_content-inner{
		padding-right: var(--padding-zero);
	}

	.location-one_map-column{
		margin-bottom: var(--margin-bottom-25);
	}

	.rtl .business-one_image-two{
		left: 20px;
		right: 0px;
	}

}

@media only screen and (max-width: 767px){
	
	.main-header .header-upper .logo-outer {
		position:relative;
		width:100%;
	}
	
	.main-header .sticky-header .outer-box{
		display:block;
		right: 0px;
		left: auto;
		top:0px;
	}
	
	.main-header .main-menu{
		width:100%;
		display:none;
	}
	
	.sec-title .text br,
	.header-top_socials,
	.main-header .main-menu .navbar-collapse > .navigation li.dropdown:after,
	.main-header .main-menu .navigation > li > ul:before{
		display:none !important;	
	}
	
	.main-header .main-box .logo-box{
		float:none;
		text-align:center;
		padding-bottom:10px;
	}
	
	.location-one_map-column{
		margin-bottom: var(--margin-bottom-30);
	}

	.main-header .header-lower .outer-box{
		position:relative;
		z-index:12;
		display:block;
	}
	
	.main-header .main-menu .navbar-header .navbar-toggler{
		display: inline-block;
		z-index: 12;
		width: 50px;
		height: 40px;
		float: none;
		padding: 0px;
		text-align: center;
		border-radius: 0px;
		background: none;
		border: 1px solid #ffffff;
	}
	
	.main-header .main-menu .navbar-header .navbar-toggler .icon-bar{
		position: relative;
		background: #ffffff;
		height: 2px;
		width: 26px;
		display: block;
		margin:0 auto;
		margin:5px 11px;
	}
	
	.main-header .nav-outer{
		width:100%;
	}
	
	.main-header .nav-outer .mobile-nav-toggler{
		display: block;
	}
	
	.main-header #navbarSupportedContent{
		display:block;
	}
	
	.main-header .mCSB_inside > .mCSB_container{
		margin-right:0px;
	}
	
	.main-header .outer-box{
		position:relative;
		left:0px;
		margin-left:0px;
		padding:0px 0px;
	}
	
	.main-header .sticky-header .mobile-nav-toggler{
		display:block;
		padding:15px 0px;
	}
	
	.main-header .outer-box .nav-btn{
		display:block;
	}

	.slider-one .owl-nav{
		right: 20px;
	}

	.feature-block_one-title a br,
	.slider-one_pattern{
		display: none;
	}

	.leadership-one_block-content{
		padding-left: var(--padding-zero);
	}

	.leadership-one_block-image{
		position: relative;
		margin-bottom: var(--margin-bottom-30);
	}

	.leadership-one_block.style-two .leadership-one_block-content,
	.feature-block_one-inner{
		padding-right: var(--padding-zero);
	}

	.feature-one_pattern,
	.video-one_pattern-one,
	.footer-two_icon-seven,
	.feature-block_one-inner::before{
		display: none;
	}

	.price-block_one-inner{
		text-align: center;
	}

	.price-block_one-pattern{
		background-position: right bottom;
		background-size: cover;
	}

	h2{
		line-height: 40px;
		font-size: var(--font-30);
	}

	.subscribe-one_heading{
		margin-bottom: var(--margin-bottom-20);
	}

	.subscribe-one .inner-container{
		padding: 40px 30px;
	}

	.event-two .event-block_two {
		width: 50%;
	}

	.price-block_two-color_layer{
		top: 25px;
	}

	.header-two {
		top: 15px;
	}

	.speaker-tabs_two .tab-btns .tab-btn{
		margin: 0px 0px 15px;
	}

	.price-block_three-inner{
		text-align: center;
	}

	.privacy-section h4 br{
		display: none;
	}

	.event-block_three-inner{
		min-height: auto;
		padding-left: var(--padding-zero);
	}

	.event-block_three-room{
		position: relative;
		margin-bottom: var(--margin-bottom-25);
	}

	.rtl .leadership-one_block-content{
		padding-right: var(--padding-zero);
	}

	.rtl .testimonial-one .owl-nav{
		left: 0px;
		right: auto;
	}

	.rtl .leadership-three_block-content{
		padding-right: var(--padding-zero);
	}
	
}

@media only screen and (max-width: 599px){

	h1 {
		line-height: 50px;
		font-size: var(--font-40);
	}

	.leadership-two_block-content{
		padding-left: var(--padding-zero);
	}

	.leadership-two_block.style-two .leadership-two_block-content{
		padding-left: var(--padding-left-20);
	}

	.speakers-four_pattern,
	.speakers-three_pattern,
	.footer-two_icon-eight,
	.appointmeny_button-box{
		display: none;
	}
	
	.main-header .mobile-nav-toggler{
		margin-left: var(--margin-zero);
	}

	.about-one_bold-text{
		line-height: 28px;
		font-size: var(--font-18);
	}

	.news-block_one-content{
		padding: 40px 20px 30px;
		margin: var(--margin-zero);
	}

	.leadership-two_block.style-two .leadership-two_block-content::before{
		border-radius: 0px;
	}

	.price-block_two-color_layer {
		top: 10px;
	}

	.subscribe-box.style-two .form-group button{
		top: 4px;
		right: 0px;
	}

	.slider-three_heading {
		line-height: 50px;
		font-size: var(--font-40);
	}

	.coming-soon .content .content-inner h2{
		margin-bottom: var(--margin-bottom-50);
	}

	.time-counter .time-countdown{
		margin-bottom: var(--margin-bottom-50);
	}

	.news-block_one-image-two{
		margin-bottom: var(--margin-bottom-30);
	}

	.blog-author-box .author-inner{
		min-height: auto;
		padding-right: var(--padding-zero);
	}

	.blog-author-box .thumb{
		position: relative;
		margin-bottom: var(--margin-bottom-25);
	}

	.blog-author-box .social-box{
		top: 0px;
		right: 0px;
	}

	.comments-content{
		padding: 25px 20px;
	}

	.reply-comment{
		padding: 25px 20px;
	}

	.blog-detail .two-column{
		margin-bottom: var(--margin-zero);
	}

	.blog-detail_content{
		padding: 45px 20px 25px;
	}

	.blog-detail_heading{
		line-height: 30px;
		font-size: var(--font-20);
	}

	blockquote{
		line-height: 26px;
		padding: 20px 20px;
		font-size: var(--font-16);
	}

	.error-section h1{
		font-size: 150px;
	}

}

@media only screen and (max-width: 479px) {
	
	h1 {
		font-size: 30px;
		line-height: 40px;
	}
	
	h2 {
		line-height: 34px;
		font-size: var(--font-24);
	}

	h3 {
		line-height: 36px;
		font-size: var(--font-26);
	}

	h4 {
		line-height: 32px;
		font-size: var(--font-22);
	}

	.business-one_image-two,
	.about-one_pattern-three,
	.testimonial-block_one-lower::before,
	.main-header.header-two .search-box-btn,
	.speakers-one_pattern{
		display: none;
	}

	.leadership-one_block-inner {
		padding: 15px 15px;
	}

	.price-block_one-inner{
		padding: 100px 20px 70px;
	}

	.news-one .title-box{
		margin-bottom: var(--margin-bottom-25);
	}

	.subscribe-box .form-group button{
		position: relative;
		width: 100%;
		display: block;
		margin-top: var(--margin-top-5);
	}

	.footer-social_list{
		margin-top: var(--margin-top-25);
	}

	.slider-three_heading {
		line-height: 42px;
		font-size: var(--font-30);
	}

	.leadership-three_block-inner {
		padding: 20px 20px;
	}

	.testimonial-one .owl-nav{
		position: relative;
		top: 0px;
	}

	.testimonial-block_one-content{
		padding: 30px 20px;
	}

	.testimonial-block_one-rating{
		margin-left: var(--margin-zero);
	}

	.testimonial-block_one-lower{
		margin-left: var(--margin-zero);
		margin-top: var(--margin-top-25);
	}

	.testimonial-block_one-lower{
		padding-top: var(--padding-top-15);
	}

	.news-block_three-content{
		margin: var(--margin-zero);
	}

	.news-block_three-content{
		padding: 25px 25px;
	}

	.news-three .title-box{
		margin-bottom: var(--margin-bottom-25);
	}

	.header-two .header-upper .inner-container{
		padding: 0px 20px;
	}

	.page-title_heading{
		line-height: 40px;
		font-size: var(--font-30);
		margin-bottom: var(--margin-bottom-20);
	}

	.sidebar-widget .widget-content{
		padding: 25px 25px;
	}	

	.coming-soon .emailed-form .text,
	.accordion-box .block .acc-btn{
		font-size: var(--font-16);
	}

	.styled-pagination li{
		margin: 0px 0px 5px;
	}

	.blog-author-box .social-box{
		position: relative;
		margin-top: var(--margin-top-10);
	}

	.comment-box .comment{
		padding-left: var(--padding-zero);
	}

	.comment-box .author-thumb{
		position: relative;
		margin-bottom: var(--margin-bottom-15);
	}

	.error-section h1{
		font-size: 100px;
	}

	.rtl .comment-box .comment{
		padding-right: var(--padding-zero);
	}

}