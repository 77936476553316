@font-face {
  font-family: "Flaticon";
  src: url("../fonts/flaticon_conat-flat.eot");
  src: url("../fonts/flaticon_conat-flat.eot?#iefix") format("embedded-opentype"),
       url("../fonts/flaticon_conat-flat.woff") format("woff"),
       url("../fonts/flaticon_conat-flat.ttf") format("truetype"),
       url("../fonts/flaticon_conat-flat.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("../fonts/flaticon_conat-flat.svg#Flaticon") format("svg");
  }
}

.flaticon-play-button:before {
    content: "\f101";
}
.flaticon-apple-logo:before {
    content: "\f102";
}
.flaticon-blocks-with-angled-cuts:before {
    content: "\f103";
}
.flaticon-home-page:before {
    content: "\f104";
}
.flaticon-angle-arrow-pointing-to-right:before {
    content: "\f105";
}
.flaticon-media-play-symbol:before {
    content: "\f106";
}
.flaticon-calendar:before {
    content: "\f107";
}
.flaticon-play-arrow:before {
    content: "\f108";
}
.flaticon-chat-bubbles-with-ellipsis:before {
    content: "\f109";
}
.flaticon-arrow-pointing-to-right:before {
    content: "\f10a";
}
.flaticon-telephone:before {
    content: "\f10b";
}
.flaticon-big-anchor:before {
    content: "\f10c";
}
.flaticon-security:before {
    content: "\f10d";
}
.flaticon-plus-symbol:before {
    content: "\f10e";
}
.flaticon-right-arrow:before {
    content: "\f10f";
}
.flaticon-left-arrow:before {
    content: "\f110";
}
.flaticon-email:before {
    content: "\f111";
}
.flaticon-magnifying-glass:before {
    content: "\f112";
}
.flaticon-user:before {
    content: "\f113";
}
.flaticon-money-bag:before {
    content: "\f114";
}
.flaticon-dollar-symbol:before {
    content: "\f115";
}
.flaticon-garbage:before {
    content: "\f116";
}
.flaticon-next:before {
    content: "\f117";
}
.flaticon-placeholder:before {
    content: "\f118";
}
.flaticon-heart:before {
    content: "\f119";
}
.flaticon-settings:before {
    content: "\f11a";
}
.flaticon-like:before {
    content: "\f11b";
}
.flaticon-avatar:before {
    content: "\f11c";
}
.flaticon-share:before {
    content: "\f11d";
}
.flaticon-house:before {
    content: "\f11e";
}
.flaticon-cancel:before {
    content: "\f11f";
}
.flaticon-headphones:before {
    content: "\f120";
}
.flaticon-phone-call:before {
    content: "\f121";
}
.flaticon-shopping-cart:before {
    content: "\f122";
}
.flaticon-envelope:before {
    content: "\f123";
}
.flaticon-menu:before {
    content: "\f124";
}
.flaticon-menu-1:before {
    content: "\f125";
}
.flaticon-multiply:before {
    content: "\f126";
}
.flaticon-stopwatch:before {
    content: "\f127";
}
.flaticon-search:before {
    content: "\f128";
}
.flaticon-unlink:before {
    content: "\f129";
}
.flaticon-list:before {
    content: "\f12a";
}
.flaticon-left-quote:before {
    content: "\f12b";
}
.flaticon-right-quote:before {
    content: "\f12c";
}
.flaticon-wifi:before {
    content: "\f12d";
}
.flaticon-paper-plane:before {
    content: "\f12e";
}
.flaticon-pdf:before {
    content: "\f12f";
}
.flaticon-edit:before {
    content: "\f130";
}
.flaticon-eye:before {
    content: "\f131";
}
.flaticon-placeholder-1:before {
    content: "\f132";
}
.flaticon-settings-1:before {
    content: "\f133";
}
.flaticon-chat:before {
    content: "\f134";
}
.flaticon-home:before {
    content: "\f135";
}
.flaticon-megaphone:before {
    content: "\f136";
}
.flaticon-padlock:before {
    content: "\f137";
}
.flaticon-message:before {
    content: "\f138";
}
.flaticon-playstore:before {
    content: "\f139";
}
.flaticon-down-arrow:before {
    content: "\f13a";
}
.flaticon-next-1:before {
    content: "\f13b";
}
.flaticon-secure-shield:before {
    content: "\f13c";
}
.flaticon-trophy:before {
    content: "\f13d";
}
.flaticon-maps-and-flags:before {
    content: "\f13e";
}
.flaticon-bar-chart:before {
    content: "\f13f";
}
.flaticon-right:before {
    content: "\f140";
}
.flaticon-call:before {
    content: "\f141";
}
.flaticon-play-button-1:before {
    content: "\f142";
}
.flaticon-search-1:before {
    content: "\f143";
}
.flaticon-call-1:before {
    content: "\f144";
}
.flaticon-hand-shake:before {
    content: "\f145";
}
.flaticon-map:before {
    content: "\f146";
}
.flaticon-user-1:before {
    content: "\f147";
}
.flaticon-checked:before {
    content: "\f148";
}
.flaticon-briefcase:before {
    content: "\f149";
}
.flaticon-eye-1:before {
    content: "\f14a";
}
.flaticon-like-1:before {
    content: "\f14b";
}
.flaticon-down-arrow-1:before {
    content: "\f14c";
}
.flaticon-up-arrow:before {
    content: "\f14d";
}
.flaticon-left-arrow-1:before {
    content: "\f14e";
}
.flaticon-right-arrow-1:before {
    content: "\f14f";
}
.flaticon-list-1:before {
    content: "\f150";
}
.flaticon-email-1:before {
    content: "\f151";
}
.flaticon-quote:before {
    content: "\f152";
}
.flaticon-menu-2:before {
    content: "\f153";
}
.flaticon-clock:before {
    content: "\f154";
}
.flaticon-full-screen:before {
    content: "\f155";
}
.flaticon-reply-all:before {
    content: "\f156";
}
.flaticon-megaphone-1:before {
    content: "\f157";
}
.flaticon-music-note:before {
    content: "\f158";
}
.flaticon-iphone:before {
    content: "\f159";
}
.flaticon-link:before {
    content: "\f15a";
}
.flaticon-star:before {
    content: "\f15b";
}
.flaticon-wall-clock:before {
    content: "\f15c";
}
.flaticon-line-chart:before {
    content: "\f15d";
}
.flaticon-quote-1:before {
    content: "\f15e";
}
.flaticon-play:before {
    content: "\f15f";
}
.flaticon-instagram:before {
    content: "\f160";
}
.flaticon-pinterest:before {
    content: "\f161";
}
.flaticon-twitter:before {
    content: "\f162";
}
.flaticon-whatsapp:before {
    content: "\f163";
}
.flaticon-phone-call-1:before {
    content: "\f164";
}
.flaticon-cancel-1:before {
    content: "\f165";
}
.flaticon-connection:before {
    content: "\f166";
}
.flaticon-law:before {
    content: "\f167";
}
.flaticon-plus:before {
    content: "\f168";
}
.flaticon-trophy-1:before {
    content: "\f169";
}
.flaticon-placeholder-2:before {
    content: "\f16a";
}
.flaticon-heart-1:before {
    content: "\f16b";
}
.flaticon-calendar-1:before {
    content: "\f16c";
}
.flaticon-email-2:before {
    content: "\f16d";
}
.flaticon-support:before {
    content: "\f16e";
}
.flaticon-left-arrow-2:before {
    content: "\f16f";
}
.flaticon-check:before {
    content: "\f170";
}
.flaticon-down-arrow-2:before {
    content: "\f171";
}
.flaticon-time:before {
    content: "\f172";
}
.flaticon-next-2:before {
    content: "\f173";
}
.flaticon-shopping-bag:before {
    content: "\f174";
}
.flaticon-loupe:before {
    content: "\f175";
}
.flaticon-briefcase-1:before {
    content: "\f176";
}
.flaticon-light-bulb:before {
    content: "\f177";
}
.flaticon-planet-earth:before {
    content: "\f178";
}
.flaticon-headphones-1:before {
    content: "\f179";
}
.flaticon-conversation:before {
    content: "\f17a";
}
.flaticon-check-1:before {
    content: "\f17b";
}
.flaticon-call-2:before {
    content: "\f17c";
}
.flaticon-portfolio:before {
    content: "\f17d";
}
.flaticon-user-2:before {
    content: "\f17e";
}
.flaticon-mouse:before {
    content: "\f17f";
}
.flaticon-phone:before {
    content: "\f180";
}
.flaticon-trophy-2:before {
    content: "\f181";
}
.flaticon-video-camera:before {
    content: "\f182";
}
.flaticon-shield:before {
    content: "\f183";
}
.flaticon-shopping-cart-1:before {
    content: "\f184";
}
.flaticon-pen:before {
    content: "\f185";
}
.flaticon-squares:before {
    content: "\f186";
}
.flaticon-web-programming:before {
    content: "\f187";
}
.flaticon-share-1:before {
    content: "\f188";
}
.flaticon-folder:before {
    content: "\f189";
}
.flaticon-youtube:before {
    content: "\f18a";
}
.flaticon-group:before {
    content: "\f18b";
}
.flaticon-high-signal:before {
    content: "\f18c";
}
.flaticon-policy:before {
    content: "\f18d";
}
.flaticon-clock-1:before {
    content: "\f18e";
}
.flaticon-arrows:before {
    content: "\f18f";
}
.flaticon-handshake:before {
    content: "\f190";
}
.flaticon-download:before {
    content: "\f191";
}
.flaticon-menu-3:before {
    content: "\f192";
}
.flaticon-creative-design:before {
    content: "\f193";
}
.flaticon-tag:before {
    content: "\f194";
}
.flaticon-bubble-chat:before {
    content: "\f195";
}
.flaticon-quote-2:before {
    content: "\f196";
}
.flaticon-quote-3:before {
    content: "\f197";
}
.flaticon-envelope-1:before {
    content: "\f198";
}
.flaticon-send:before {
    content: "\f199";
}
.flaticon-right-arrow-2:before {
    content: "\f19a";
}
.flaticon-down-arrow-3:before {
    content: "\f19b";
}
.flaticon-checked-1:before {
    content: "\f19c";
}
.flaticon-settings-2:before {
    content: "\f19d";
}
.flaticon-shopping-cart-2:before {
    content: "\f19e";
}
.flaticon-pinterest-1:before {
    content: "\f19f";
}
.flaticon-document:before {
    content: "\f1a0";
}
.flaticon-notepad:before {
    content: "\f1a1";
}
.flaticon-clock-2:before {
    content: "\f1a2";
}
.flaticon-book:before {
    content: "\f1a3";
}
.flaticon-placard:before {
    content: "\f1a4";
}
.flaticon-deal:before {
    content: "\f1a5";
}
.flaticon-idea:before {
    content: "\f1a6";
}
.flaticon-shopping-basket:before {
    content: "\f1a7";
}
.flaticon-email-3:before {
    content: "\f1a8";
}
.flaticon-double-check:before {
    content: "\f1a9";
}
.flaticon-digital-learning:before {
    content: "\f1aa";
}
.flaticon-gear:before {
    content: "\f1ab";
}
.flaticon-right-1:before {
    content: "\f1ac";
}
.flaticon-red-carpet:before {
    content: "\f1ad";
}
.flaticon-red-carpet-1:before {
    content: "\f1ae";
}
.flaticon-minus:before {
    content: "\f1af";
}
.flaticon-bag:before {
    content: "\f1b0";
}
.flaticon-training:before {
    content: "\f1b1";
}
.flaticon-padlock-1:before {
    content: "\f1b2";
}
.flaticon-lamp:before {
    content: "\f1b3";
}
.flaticon-atom:before {
    content: "\f1b4";
}
.flaticon-thumbs-up:before {
    content: "\f1b5";
}
.flaticon-bulb:before {
    content: "\f1b6";
}
.flaticon-inspiration:before {
    content: "\f1b7";
}
.flaticon-menu-4:before {
    content: "\f1b8";
}
.flaticon-calendar-2:before {
    content: "\f1b9";
}
.flaticon-trophy-3:before {
    content: "\f1ba";
}
.flaticon-add-to-cart:before {
    content: "\f1bb";
}
.flaticon-play-1:before {
    content: "\f1bc";
}
.flaticon-poinsettia:before {
    content: "\f1bd";
}
.flaticon-tik-tok:before {
    content: "\f1be";
}
.flaticon-heart-2:before {
    content: "\f1bf";
}
.flaticon-conversation-1:before {
    content: "\f1c0";
}
.flaticon-briefcase-2:before {
    content: "\f1c1";
}
.flaticon-atom-1:before {
    content: "\f1c2";
}
.flaticon-facebook:before {
    content: "\f1c3";
}
.flaticon-behance:before {
    content: "\f1c4";
}
.flaticon-target:before {
    content: "\f1c5";
}
.flaticon-reload:before {
    content: "\f1c6";
}
.flaticon-pdf-1:before {
    content: "\f1c7";
}
.flaticon-calendar-3:before {
    content: "\f1c8";
}
.flaticon-placeholder-3:before {
    content: "\f1c9";
}
.flaticon-people:before {
    content: "\f1ca";
}
.flaticon-tourist:before {
    content: "\f1cb";
}
.flaticon-smile:before {
    content: "\f1cc";
}
.flaticon-clock-3:before {
    content: "\f1cd";
}
.flaticon-linkedin:before {
    content: "\f1ce";
}
.flaticon-party:before {
    content: "\f1cf";
}
.flaticon-location:before {
    content: "\f1d0";
}
.flaticon-telephone-1:before {
    content: "\f1d1";
}
.flaticon-red-carpet-2:before {
    content: "\f1d2";
}
.flaticon-youtube-1:before {
    content: "\f1d3";
}
.flaticon-instagram-1:before {
    content: "\f1d4";
}
.flaticon-hamburger:before {
    content: "\f1d5";
}
.flaticon-countries:before {
    content: "\f1d6";
}
.flaticon-mic:before {
    content: "\f1d7";
}
.flaticon-creative-mind:before {
    content: "\f1d8";
}
.flaticon-voice:before {
    content: "\f1d9";
}
.flaticon-leader:before {
    content: "\f1da";
}
.flaticon-cloud-download:before {
    content: "\f1db";
}
