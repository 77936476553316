.empty-animation{
    animation-name: zoomInStable; 
    animation-duration: 3s; 
    animation-iteration-count: 1;
    animation-timing-function: linear;
  
  -webkit-animation-name: zoomInStable; 
    -webkit-animation-duration: 3s; 
    -webkit-animation-iteration-count: 1;
    -webkit-animation-timing-function: linear;
  
  -moz-animation-name: zoomInStable; 
    -moz-animation-duration: 3s; 
    -moz-animation-iteration-count: 1;
    -moz-animation-timing-function: linear;
  
  -ms-animation-name: zoomInStable; 
    -ms-animation-duration: 3s; 
    -ms-animation-iteration-count: 1;
    -ms-animation-timing-function: linear;
  
  -o-animation-name: zoomInStable; 
    -o-animation-duration: 3s; 
    -o-animation-iteration-count: 1;
    -o-animation-timing-function: linear;
}

.empty-animation{
    animation-name: zoomInStable; 
    animation-duration: 3s; 
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  
  -webkit-animation-name: zoomInStable; 
    -webkit-animation-duration: 3s; 
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
  
  -moz-animation-name: zoomInStable; 
    -moz-animation-duration: 3s; 
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
  
  -ms-animation-name: zoomInStable; 
    -ms-animation-duration: 3s; 
    -ms-animation-iteration-count: infinite;
    -ms-animation-timing-function: linear;
  
  -o-animation-name: zoomInStable; 
    -o-animation-duration: 3s; 
    -o-animation-iteration-count: infinite;
    -o-animation-timing-function: linear;
}

.empty-animation{
    animation-name: slideInUp; 
    animation-duration: 2s; 
    animation-iteration-count: 1;
    animation-timing-function: linear;
  
  -webkit-animation-name: slideInUp; 
    -webkit-animation-duration: 2s; 
    -webkit-animation-iteration-count: 1;
    -webkit-animation-timing-function: linear;
  
  -moz-animation-name: slideInUp; 
    -moz-animation-duration: 2s; 
    -moz-animation-iteration-count: 1;
    -moz-animation-timing-function: linear;
  
  -ms-animation-name: slideInUp; 
    -ms-animation-duration: 2s; 
    -ms-animation-iteration-count: 1;
    -ms-animation-timing-function: linear;
  
}

.empty-animation{
    animation-name: slideInRight; 
    animation-duration: 2s; 
    animation-iteration-count: 1;
    animation-timing-function: linear;
  
  -webkit-animation-name: slideInRight; 
    -webkit-animation-duration: 2s; 
    -webkit-animation-iteration-count: 1;
    -webkit-animation-timing-function: linear;
  
  -moz-animation-name: slideInRight; 
    -moz-animation-duration: 2s; 
    -moz-animation-iteration-count: 1;
    -moz-animation-timing-function: linear;
  
  -ms-animation-name: slideInRight; 
    -ms-animation-duration: 2s; 
    -ms-animation-iteration-count: 1;
    -ms-animation-timing-function: linear;
  
}

.empty-animation{
    animation-name: slideInLeft; 
    animation-duration: 2s; 
    animation-iteration-count: 1;
    animation-timing-function: linear;
  
  -webkit-animation-name: slideInLeft; 
    -webkit-animation-duration: 2s; 
    -webkit-animation-iteration-count: 1;
    -webkit-animation-timing-function: linear;
  
  -moz-animation-name: slideInLeft; 
    -moz-animation-duration: 2s; 
    -moz-animation-iteration-count: 1;
    -moz-animation-timing-function: linear;
  
  -ms-animation-name: slideInLeft; 
    -ms-animation-duration: 2s; 
    -ms-animation-iteration-count: 1;
    -ms-animation-timing-function: linear;
  
}

.empty-animation{
    animation-name: slideInUp; 
    animation-duration: 2s; 
    animation-iteration-count: 1;
    animation-timing-function: linear;
  
  -webkit-animation-name: slideInUp; 
    -webkit-animation-duration: 2s; 
    -webkit-animation-iteration-count: 1;
    -webkit-animation-timing-function: linear;
  
  -moz-animation-name: slideInUp; 
    -moz-animation-duration: 2s;
    -moz-animation-iteration-count: 1;
    -moz-animation-timing-function: linear;
  
  -ms-animation-name: slideInUp; 
    -ms-animation-duration: 2s; 
    -ms-animation-iteration-count: 1;
    -ms-animation-timing-function: linear;
  
}

@keyframes rotateme {
    
     0% {
        transform: rotate(0deg);
        opacity: 1;
      }

    50% {
        transform: rotate(180deg);
      }
    100% {
        transform: rotate(360deg);
        opacity: 1;
      }
}

.about-two_pattern-three,
.price-one_pattern,
.speakers-four_pattern,
.counter-three_inner::before,
.video-one_pattern-three,
.leadership-one_icon-four,
.leadership-one_icon-two,
.speakers-one_pattern,
.about-one_pattern-three,
.feature-block_one-icon::after{
    animation-name: rotateme; 
    animation-duration: 15s; 
    animation-iteration-count: infinite;
    animation-timing-function: linear;
	
	-webkit-animation-name: rotateme; 
    -webkit-animation-duration: 15s; 
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
	
	-moz-animation-name: rotateme; 
    -moz-animation-duration: 15s; 
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
	
	-ms-animation-name: rotateme; 
    -ms-animation-duration: 15s; 
    -ms-animation-iteration-count: infinite;
    -ms-animation-timing-function: linear;
	
	-o-animation-name: rotateme; 
    -o-animation-duration: 15s; 
    -o-animation-iteration-count: infinite;
    -o-animation-timing-function: linear;
}



@keyframes rotateme2 {
    
     0% {
        transform: rotate(0deg);
        opacity: 1;
      }

    50% {
        transform: rotate(-180deg);
      }
    100% {
        transform: rotate(-360deg);
        opacity: 1;
      }
}

.video-one_pattern-two,
.leadership-one_icon-three,
.speakers-one_pattern-two{
    animation-name: rotateme2;
    animation-duration: 15s; 
    animation-iteration-count: infinite;
    animation-timing-function: linear;
	
	-webkit-animation-name: rotateme2; 
    -webkit-animation-duration: 15s; 
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
	
	-moz-animation-name: rotateme2; 
    -moz-animation-duration: 15s; 
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
	
	-ms-animation-name: rotateme2; 
    -ms-animation-duration: 15s; 
    -ms-animation-iteration-count: infinite;
    -ms-animation-timing-function: linear;
	
	-o-animation-name: rotateme2; 
    -o-animation-duration: 15s; 
    -o-animation-iteration-count: infinite;
    -o-animation-timing-function: linear;
}

/* Bob Up Down*/
@-webkit-keyframes float_up_down {
  0% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    opacity: 1;
  }

  50% {
    -webkit-transform: translateY(30px);
    transform: translateY(30px);
    opacity: 1;
  }

  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    opacity: 1;
  }
}

@keyframes float_up_down {
  0% {
    transform: translateY(0px);
    transform: translateY(0px);
    opacity: 1;
  }

  50% {
    transform: translateY(30px);
    transform: translateY(30px);
    opacity: 1;
  }

  100% {
    transform: translateY(0px);
    transform: translateY(0px);
    opacity: 1;
  }
}

.video-one_pattern-four,
.video-one_pattern-one,
.leadership-one_icon{
    animation-name: float_up_down; 
    animation-duration: 5s; 
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    
    -webkit-animation-name: float_up_down; 
    -webkit-animation-duration: 5s; 
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    
    -moz-animation-name: float_up_down; 
    -moz-animation-duration: 5s; 
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
    
    -ms-animation-name: float_up_down; 
    -ms-animation-duration: 5s; 
    -ms-animation-iteration-count: infinite;
    -ms-animation-timing-function: linear;
    
    -o-animation-name: float_left_right; 
    -o-animation-duration: 5s; 
    -o-animation-iteration-count: infinite;
    -o-animation-timing-function: linear;
}

/* Bob */
@-webkit-keyframes float_left_right {
  0% {
    -webkit-transform: translateX(-100px);
    transform: translateX(-100px);
    opacity: 1;
  }

  50% {
    -webkit-transform: translateX(100px);
    transform: translateX(100px);
    opacity: 0.40;
  }

  100% {
    -webkit-transform: translateX(-100px);
    transform: translateX(-100px);
    opacity: 1;
  }
}

@keyframes float_left_right {
  0% {
    -webkit-transform: translateX(-100px);
    transform: translateX(-100px);
    opacity: 1;
  }

  50% {
    -webkit-transform: translateX(100px);
    transform: translateX(100px);
    opacity: 0.40;
  }

  100% {
    -webkit-transform: translateX(-100px);
    transform: translateX(-100px);
    opacity: 1;
  }
}

.empty-animation{
    animation-name: float_left_right; 
    animation-duration: 30s; 
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  
  -webkit-animation-name: float_left_right; 
    -webkit-animation-duration: 30s; 
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
  
  -moz-animation-name: float_left_right; 
    -moz-animation-duration: 30s; 
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
  
  -ms-animation-name: float_left_right; 
    -ms-animation-duration: 30s; 
    -ms-animation-iteration-count: infinite;
    -ms-animation-timing-function: linear;
  
  -o-animation-name: float_left_right; 
    -o-animation-duration: 30s; 
    -o-animation-iteration-count: infinite;
    -o-animation-timing-function: linear;
}

@-webkit-keyframes float_left_right_two {
  0% {
    -webkit-transform: translateX(-30px);
    opacity: 1;
  }

  50% {
    -webkit-transform: translateX(0px);
    opacity: 0.80;
  }

  100% {
    -webkit-transform: translateX(-30px);
    opacity: 1;
  }
}

@keyframes float_left_right_two {
  0% {
    transform: translateX(-30px);
    opacity: 1;
  }

  50% {
    transform: translateX(0px);
    opacity: 0.80;
  }

  100% {
    transform: translateX(-30px);
    opacity: 1;
  }
}


.empty-animation{
    animation-name: float_left_right_two; 
    animation-duration: 5s; 
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  
  -webkit-animation-name: float_left_right_two; 
    -webkit-animation-duration: 5s; 
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
  
  -moz-animation-name: float_left_right_two; 
    -moz-animation-duration: 5s; 
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
  
  -ms-animation-name: float_left_right_two; 
    -ms-animation-duration: 5s; 
    -ms-animation-iteration-count: infinite;
    -ms-animation-timing-function: linear;
  
  -o-animation-name: float_left_right_two; 
    -o-animation-duration: 5s; 
    -o-animation-iteration-count: infinite;
    -o-animation-timing-function: linear;
}


@-webkit-keyframes zoom-fade {
  0% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }

  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  100% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
}

@keyframes zoom-fade {
  0% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }

  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  100% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
}

.about-one_pattern-four,
.slider-one_pattern{
    animation-name: zoom-fade; 
    animation-duration: 6s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
	
	-webkit-animation-name: zoom-fade; 
    -webkit-animation-duration: 6s; 
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
	
	-moz-animation-name: zoom-fade; 
    -moz-animation-duration: 6s;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
	
	-ms-animation-name: zoom-fade; 
    -ms-animation-duration: 6s;
    -ms-animation-iteration-count: infinite;
    -ms-animation-timing-function: linear;
	
	-o-animation-name: zoom-fade; 
    -o-animation-duration: 6s; 
    -o-animation-iteration-count: infinite;
    -o-animation-timing-function: linear;
}


@-webkit-keyframes icon-bounce {
  0%, 100%, 20%, 50%, 80% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  60% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }
}
@keyframes icon-bounce {
  0%, 100%, 20%, 50%, 80% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  60% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }
}